import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'
import cn from 'classnames'

import Page from '../../components/Page'
import Schema from '../../components/Schema'
import HeaderInfo from './../../components/articles/HeaderInfo'
import ShareButtons from '../../components/articles/ShareButtons'
import Content from '../../components/articles/Content'
import NewsArticleLink from '../../components/articles/Listings/NewsArticleLink'
import Newsletter from '../../components/Newsletter'

export default function PodcastTemplate({ data }) {
  const { article, site, appleImage, spotifyImage, relatedArticles } = data
  const shareUrl = encodeURI(
    `${site.siteMetadata.siteUrl}${article.categoryLink}/${article.slug}`
  )

  const breadcrumbs = [
    {
      name: 'News',
      uri: '/news',
    },
    {
      name: article.category,
      uri: article.categoryLink,
    },
    {
      name: article.title,
      uri: `${article.categoryLink}/${article.slug}`,
    },
  ]

  const podcastUrl = `//html5-player.libsyn.com/embed/episode/id/${article.podcastId}/height/90/theme/custom/thumbnail/yes/direction/backward/render-playlist/no/custom-color/26525d/`

  return (
    <Page className="p-article p-newsArticle has-fingerPrints" datoIndex>
      <HelmetDatoCms seo={article.seoMetaTags} />
      <Schema breadcrumbs={breadcrumbs} />
      {article.canonicalUrl && (
        <Helmet
          link={[
            {
              rel: 'canonical',
              key: article.canonicalUrl,
              href: article.canonicalUrl,
            },
          ]}
        />
      )}

      <article id={article.id} className="c-article">
        <div className="container py-5">
          <header>
            <HeaderInfo
              date={article.pubDateTime}
              category={article.category}
              categoryLink={article.categoryLink}
            />
            <div className="row">
              <div className="col-12 col-lg-9 offset-lg-3">
                <h1 className="c-articleTitle">{article.title}</h1>
              </div>
            </div>
          </header>

          {/* <!-- Hero --> */}
          <section>
            <div className="row">
              <div className="col-12 col-lg-3 mb-2">
                <ShareButtons shareUrl={shareUrl} />
              </div>
              <div className="col-12 col-lg-9">
                {article.heroImage && (
                  <div className="c-articleHeroImageWrapper">
                    <Img
                      fluid={article.heroImage.fluid}
                      title={article.heroImage.title}
                      alt={article.heroImage.alt}
                      loading="eager"
                      className="c-articleHeroImage img-fluid"
                    />
                  </div>
                )}
                {/* <!-- Body --> */}
                <Content content={article.content} />
                <iframe
                  style={{ border: 'none' }}
                  src={podcastUrl}
                  height="90"
                  width="100%"
                  scrolling="no"
                  allow="fullscreen;"
                ></iframe>
                <div className="c-articleContent row">
                  <div className="c-articleContentCol col-12">
                    <hr />
                    <p>
                      You can listen the episode above or you can search for the
                      Erudus Podcast on your favourite podcast player to
                      subscribe;
                    </p>
                  </div>
                  <div className="c-articleContentCol col-6 text-center">
                    <a
                      href="https://podcasts.apple.com/gb/podcast/the-erudus-podcast/id1489622496"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={appleImage.childImageSharp.fixed.src}
                        srcSet={appleImage.childImageSharp.fixed.srcSet}
                        title="Listen to us on Apple Podcasts now!"
                        alt="Apple Podcasts logo on a black background"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  <div className="c-articleContentCol col-6 text-center">
                    <a
                      href="https://open.spotify.com/show/23QC8b4MNbOWmWUR9HiW1t"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={spotifyImage.childImageSharp.fixed.src}
                        srcSet={spotifyImage.childImageSharp.fixed.srcSet}
                        title="Listen to us on Spotify now!"
                        alt="Spotify logo on a black background"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>

      {/* <!-- Related --> */}
      <section
        id="latest-editorial"
        className="c-latestEditorial"
        data-datocms-noindex
      >
        <div className="container">
          <div className="c-latestEditorial__inner">
            <div className="row">
              <div className="c-latestEditorial__header col-12">
                <h3 className="c-latestEditorial__header__title">
                  Related News
                </h3>
                <Link to="/news" className="c-postReturnLink">
                  <img src="/icons/arrow-right.svg" className="c-icon mr-2" />
                  <span className="c-postReturnLink__text">All News</span>
                </Link>
              </div>
            </div>

            <div className="row">
              {relatedArticles.edges.map(({ node }, i) => (
                <div
                  key={node.slug}
                  className={cn('col-6 col-lg-4', {
                    'd-none d-lg-block': i === 2,
                  })}
                >
                  <NewsArticleLink article={node} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export const pageQuery = graphql`
  query PodcastArticleQuery($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    article: datoCmsPodcastArticle(slug: { eq: $slug }) {
      title
      slug
      canonicalUrl
      category
      categoryLink
      pubDateTime
      heroImage: thumbnailImage {
        fluid(maxWidth: 850, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      content {
        ...ArticleContentFragment
      }
      podcastId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    appleImage: file(
      relativePath: { eq: "articles/podcast/apple-podcast.jpg" }
    ) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    spotifyImage: file(
      relativePath: { eq: "articles/podcast/spotify-podcast.jpg" }
    ) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    relatedArticles: allDatoCmsNewsArticle(
      filter: { slug: { ne: $slug } }
      sort: { fields: displayDate, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          relPath
          category
          categoryLink
          pubDateTime
          heroImage {
            url
            alt
            title
            customData
          }
          thumbnailImage {
            url
            alt
            title
            customData
          }
        }
      }
    }
  }
`
